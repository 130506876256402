"use client";

import { cn } from "@/lib/utils";
import React from "react";

const CircularProgress = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    value?: number;
    strokeWidth?: number;
  }
>(({ className, value, strokeWidth = 8 }, ref) => {
  const circumference = 30 * 2 * Math.PI;

  return (
    <div
      className={cn(
        "inline-flex items-center justify-center overflow-hidden rounded-full",
        className,
      )}
      ref={ref}
    >
      <svg className="h-20 w-20">
        <circle
          className="text-gray-300"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
        <circle
          className="text-white"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={
            circumference - ((value || 0) / 100) * circumference
          }
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
      </svg>
      <span className="absolute text-xl text-blue-700"></span>
    </div>
  );
});
CircularProgress.displayName = "CircularProgress";

export { CircularProgress };
