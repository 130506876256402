"use client";

import {
  useCourseAcademicTerm,
  useNextItem,
} from "@/courses/context/CourseContext";
import { useMediaState } from "@vidstack/react";
import { TrackNextIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useOrganization } from "@/student/context/OrganizationContext";
import { CircularProgress } from "@/components/ui/circular-progress";
import getPiHref from "@/components/utils/getPiHref";
const VideoPlayerNextItem: React.FC = () => {
  const nextItem = useNextItem();
  const router = useRouter();
  const { slug: orgSlug } = useOrganization();
  const {
    slug: academicTermSlug,
    course: { slug: courseSlug },
  } = useCourseAcademicTerm();

  const [progress, setProgress] = useState(100);
  const [cancelled, setCancelled] = useState(false);
  const didVideoEnd = useMediaState("ended");

  useEffect(() => {
    if (!didVideoEnd) return;

    setProgress(180);
    setCancelled(false);
    const timer = window.setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
    }, 1000 / 60);
    return () => {
      window.clearInterval(timer);
    };
  }, [nextItem?.id, didVideoEnd]);

  const countdownOver = progress <= 0;

  useEffect(() => {
    if (!cancelled && didVideoEnd && countdownOver && nextItem?.slug) {
      router.push(
        getPiHref(
          `/${orgSlug}/dersler/ogren/${courseSlug}/${academicTermSlug}/${nextItem?.slug}`,
        ),
      );
    }
  }, [
    didVideoEnd,
    cancelled,
    countdownOver,
    nextItem?.slug,
    router,
    orgSlug,
    courseSlug,
    academicTermSlug,
  ]);

  if (!didVideoEnd || !nextItem || cancelled) return null;

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/40">
      <h2 className="text-lg text-white">Sıradaki:</h2>
      <h2 className="mb-2 line-clamp-1 max-w-prose text-center text-xl text-white sm:line-clamp-2">
        {nextItem.title}
      </h2>
      <div className="relative inline-flex">
        <Button
          className="absolute inset-0 hover:bg-transparent hover:text-white"
          variant="ghost"
          size="none"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setProgress(0);
          }}
        >
          <TrackNextIcon className="size-6 shrink-0" />
        </Button>
        <CircularProgress value={progress / 1.8} />
      </div>
      <Button
        variant="ghost"
        className="hover:bg-transparent hover:text-white"
        onClick={() => {
          setCancelled(true);
        }}
      >
        Vazgeç
      </Button>
    </div>
  );
};

export default VideoPlayerNextItem;
